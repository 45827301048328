//
// Nav Footer CSS
//

.nav-footer {

  ul {
    @include ul-reset;
    display: flex;
  }

  li {
    margin-left: 15px;
  }

  a {
    color: $white;
    text-transform: uppercase;
    text-decoration: none;
  }

}