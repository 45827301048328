//
// Start Page Area Selection CSS
//

$areaSelectionGap: 6px;

.start-page-area-selection {
  margin-bottom: 100px;
  display: flex;
  overflow: hidden;

  // temp - variante nicht 100% breite
  &.narrow {
    margin: auto;
    padding: 0 80px;
  }

  .image-wrap {
    position: relative;
    width: 50%;

    figure {
    }

    img {
      display: block;
    }
  }

  .area-talent {
    padding-right: $areaSelectionGap;

    &::after {
      position: absolute;
      top: calc(50% - 40px);
      right: -20px;
      content: "";
      background: $white;
      width: 40px;
      height: 40px;
      z-index: 4;
      transform: rotate(45deg);
    }
  }

  .area-company {
    padding-left: $areaSelectionGap;
  }

  figcaption {
    position: absolute;
    bottom: 50px;
    left: 50px;
    right: 50px;
    padding: 30px 30px 60px 30px;
    background-color: rgba(61, 69, 74, .65);
    color: $white;
    @include font-size(18);
    font-weight: 400;
    text-align: center;
    min-height: 170px;
    border-radius: 10px;
  }


  .btn-primary {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    white-space: nowrap;
  }


  @media only screen and (min-width: $size-XL3) {
    figcaption {
      left: 60px;
      right: 60px;
      bottom: 80px;
      padding: 50px 50px 60px 50px;
    }

    .area-talent {
      &::after {
        top: calc(50% - 60px);
        right: -30px;
        width: 60px;
        height: 60px;
      }
    }
  }


  @media only screen and (min-width: $size-XL4) {
    figcaption {
      left: 100px;
      right: 100px;
      bottom: 80px;
    }

    .area-talent {
      &::after {
        top: calc(50% - 60px);
        right: -30px;
        width: 60px;
        height: 60px;
      }
    }
  }

  @media only screen and (min-width: $size-XL5) {
    figcaption {
      left: 140px;
      right: 140px;
      bottom: 100px;
    }
  }


  @media only screen and (max-width: $size-L) {

    figcaption {
      left: 0;
      right: 0;
      bottom: 0;
      //top: 0;
      padding: 20px 30px 60px 30px;
      border-radius: 0;
      @include font-size($font-size-S);
    }

    .area-company {
      figcaption {
        left: 6px;
      }
    }

    .area-talent {

      figcaption {
        right: 6px;
      }

      &::after {
        top: calc(50% - 30px);
        right: -15px;
        width: 30px;
        height: 30px;
      }
    }

    .btn-primary {
      bottom: 20px;
    }

  }


  @media only screen and (max-width: $size-S3) {

    flex-flow: column;
    align-items: center;

    .image-wrap {
      width: 90%;

      figcaption {
        left: 0;
      }
    }

    .area-company {
      padding: 0;
    }

    .area-talent {
      padding: 0;
      margin-bottom: 20px;

      figcaption {
        right: 0;
      }

      &::after {
        display: none;
      }
    }

  }

}