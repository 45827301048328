// Registration CSS
//

.registration {
  padding: 80px 0 120px 0;
  background: #576570;

  .flex-row {
    display: flex;
    flex-flow: column;
  }

}


.registration-info {
  color: $white;
  line-height: 1.6;
  width: 100%;
  margin-bottom: 60px;

  h3 {
    margin: 0 0 6px 0;
    @include font-size($font-size-L);
  }

  ul {
    @include ul-reset;

    li {
      margin-top: 40px;
      padding-top: 100px;


      span {
        display: block;
        padding-left: 30px;
        background: url("../Images/arrow_green.svg") no-repeat left center;
        background-size: 16px 14px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.registration-free-registration {
  background: url("../Images/free_registration.svg") no-repeat left 10px;
  background-size: 70px;
}

.registration-we-check-your-profile {
  background: url("../Images/we_check_your_profile.svg") no-repeat left 10px;
  background-size: 70px;
}

.registration-we-get-in-contact {
  background: url("../Images/we_get_in_contact.svg") no-repeat left 10px;
  background-size: 80px;
}

.registration-get-tailored-offer {
  background: url("../Images/get_tailored_offer.svg") no-repeat left 10px;
  background-size: 56px;
  max-width: 550px;
  min-height: 60px;

  h3 {
    color: $green;
  }
}


@media only screen and (min-width: $size-S1) {

  .registration-info {

    ul {

      li {
        margin-top: 60px;
        padding-top: 0;
        padding-left: 115px;
        padding-right: 20px;
      }

    }

  }

}


@media only screen and (min-width: $size-S22) {

  .registration {
    background: url("../Images/bg_registration.jpg") no-repeat;
    background-size: cover;
  }

}



@media only screen and (min-width: $size-XL1) {

  .registration {

    .flex-row {
      flex-flow: row;
    }

  }


  .registration-info {
    width: 55%;
    padding-right: 20px;
    margin-bottom: 60px;
  }

}