//
// CE Text Simple CSS
//

.ce-text {
  text-align: center;
  font-weight: 300;
  max-width: $maxInnerText;
  margin: auto;
  @include font-size($font-size-L);
  padding-left: 30px;
  padding-right: 30px;

  p {
    font-weight: 300;
  }

  @media (min-width: $size-S3) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media only screen and (min-width: $size-L3) {
    @include font-size($font-size-L2);
  }
}


// on legal text pages use left align
.page-id-94,
.page-id-83,
.page-id-84,
.page-id-85 {
  .ce-text {
    text-align: left;
    @include font-size($font-size-M);

    h2 {
      margin-top: 40px;
    }
  }
}