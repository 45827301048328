//
// Process Steps CSS
//

.process-steps {
  scroll-margin-top: 120px;
  margin: 120px 0;
  text-align: center;

  dl {
    counter-reset: step-counter;
    text-align: left;
    position: relative;
    margin: 60px 0;

  }

  dt {
    color: $green;
    @include font-size(20);
    margin-bottom: 5px;
    font-weight: 400;
    padding-top: 60px;
    text-align: center;


    &:before {
      content: counter(step-counter);
      counter-increment: step-counter;
      position: absolute;
      left: 50%;
      @include font-size(28);
      height: 42px;
      width: 42px;
      background: $white;
      border: 2px solid $green;
      color: $green;
      border-radius: 50%;
      text-align: center;
      margin-left: -22px;
      padding-left: 3px;
    }
  }

  dd {
    margin-bottom: 40px;
    margin-left: 0;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  dt,
  dd {

  }


  .btn-primary {
    @include font-size(18);
    font-weight: 600;
  }


  @media only screen and (max-width: $size-M) {
    dt {
      position: relative;

      &:before {
        top: 0;
      }
    }
  }


  @media only screen and (min-width: $size-M) {

    dl {
      counter-reset: step-counter;
      text-align: left;
      position: relative;
      padding: 60px;
      margin: 60px 0;

      .vertical-line {
        position: absolute;
        content: "";
        left: 50%;
        width: 3px;
        background: $green;
        top: 0;
        bottom: 0;
        margin-left: -2px;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        height: 20px;
        z-index: 2;
      }

      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        height: 20px;
        z-index: 2;
      }
    }

    dt {
      color: $green;
      @include font-size(20);
      margin-bottom: 5px;
      font-weight: 400;
      padding-top: 0;
      text-align: left;

      &:last-of-type {
        max-width: 400px;
      }


      &:before {
        content: counter(step-counter);
        counter-increment: step-counter;
        position: absolute;
        left: 50%;
        @include font-size(28);
        height: 42px;
        width: 42px;
        background: $white;
        border: 2px solid $green;
        color: $green;
        border-radius: 50%;
        text-align: center;
        margin-left: -22px;
        padding-left: 3px;
      }
    }

    dd {
      margin-bottom: 40px;
      text-align: left;

      &:nth-of-type(2) {
        margin-bottom: 56px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    dt,
    dd {
      margin-left: 50%;
      padding-left: 50px;
      padding-right: 0;

      &:nth-of-type(even) {
        margin-left: 0;
        margin-right: 50%;
        text-align: right;
        padding-left: 0;
        padding-right: 50px;
      }
    }

  }

}