//
// Contact CSS
//

.contact {

  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;


  .ce-text-simple {
    text-align: left;
    padding: 0;
    max-width: none;

    .inner {
      padding: 0;
    }
  }


  .two-cols-w-sidebar {
    display: flex;

  }

  .sidebar {
    min-width: 300px;
    padding: 25px 0 0 70px;

    .btn-primary {

    }

    .ce-text-simple {
      @include font-size($font-size-M);
    }


    //@media screen and (max-width: $size-S5) {
    //  float: none;
    //  width: auto;
    //  margin: 0 0 30px 0;
    //}
  }

  .tx-powermail {
    h3 {
      //display: none;
    }
    fieldset {
      border: none;
      margin: 0;
      padding: 0;

      legend {
        display: none;
      }
    }
    label {
      display: block;
    }
    input {
      border: none;
      width: 100%;
      padding: 10px;
      //border-radius: 5px;
      background: $grey-light;
      line-height: 25px;
    }

    select {
      width: auto;
      background: $grey-light;
      padding: 10px;
      border: none;

    }

    textarea {
      border: none;
      width: 100%;
      padding: 10px;
      //border-radius: 5px;
      background: $grey-light;
    }

    .powermail_fieldwrap {
      margin-top: 15px;
      &:first-child {
        margin: 0;
      }
    }

    .powermail_input {
    }

    .powermail_fieldwrap_type_check {
      overflow: hidden;

      .checkbox {
        position: relative;
        padding-left: 28px;

        input {
          width: auto;
          position: absolute;
          left: 2px;
          top: 6px;
        }
      }
    }

    .powermail_submit {
      display: inline-block;
      background: $green;
      border: none;
      color: $grey-dark;
      padding: 10px 40px;
      @include font-size(18);
      font-weight: 600;
      border-radius: 50px;
      width: 200px;
    }

    .powermail_fieldwrap_type_submit {
      margin-top: 30px;
    }

    .powermail_fieldwrap_submit {
      //padding-top: 15px;
    }


    ul {
      margin: 0;
    }

    .parsley-required,
    .parsley-custom-error-message {
      @include font-size(10);
    }

  }


}