//
// Header CSS
//

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  background: $white;
  z-index: 9;

  transition: all 0.3s;
  //box-shadow: 0 4px 4px rgba(0,0,0,.1);
  height: $headerHeightCompact;
  padding: 2px 0 0 0;

  .row {
    @include clearfix;
  }

  .logo {
    float: left;
    overflow: hidden;;

    img {
      display: block;
      width: 200px;
      margin-left: -15px;
      transition: all 0.3s
    }
  }


  &.compact {
    border-bottom: 1px solid $grey-light;
    height: $headerHeightCompact;
    padding: 2px 0 0 0;

    .logo {
      img {
        width: 200px;
        margin-left: -15px;
      }
    }
  }


  @media only screen and (min-width: $size-XL1) {
    padding: 10px 0 10px 0;
    height: $headerHeightDefault;

    .logo {
      float: left;
      overflow: hidden;;

      img {
        display: block;
        width: 300px;
        margin-left: -25px;
        transition: all 0.3s
      }
    }


    &.compact {
      border-bottom: 1px solid $grey-light;
      height: $headerHeightCompact;
      padding: 2px 0 0 0;

      .logo {
        img {
          width: 200px;
          margin-left: -15px;
        }
      }
    }

  }

}

.page-layout-3 {
  .page-header {
    border-bottom: 1px solid $grey-light;
    height: $headerHeightCompact;
    padding: 2px 0 0 0;

    .logo {
      img {
        width: 200px;
        margin-left: -15px;
      }
    }
  }
}