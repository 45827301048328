//
// TG Modal Box CSS
//

.btn-more-info {
  border: 1px solid $green;
  border-radius: 50%;
  background: none;
  display: inline-block;
  line-height: 1;
}

.tg-modal-box {
  position: fixed;
  z-index: 9;
  top: 50%;
  left: 50%;
  width: 500px;
  transform: translate(-50%, -50%);
  border: 2px solid $green;
  background: $white;
  display: none;
  padding: 40px;

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid $green;
    background: $green;
    color: $white;
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 0;
  }
}

.page-dimmer {
  position: fixed;
  z-index: 8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .4);
  display: none;
  cursor: pointer;
}

.more-info-content {
  display: none;
}