//
// Benefits CSS
//

.benefits {
  background: $grey-light;
  padding: 60px 0;
  margin-top: 100px;

  h2 {
    margin: 0 0 20px 0;
  }

}

.benefits-list {
  @include ul-reset;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  color: $grey-mid2;
  margin: 0 -20px;

  li {
    padding: 20px;

    article {
      hyphens: auto;
      background: no-repeat;
      background-position: left top;
    }

    h3 {
      margin: 0 0 10px 0;
    }
  }


  @media only screen and (min-width: $size-S21) {

    li article {
      padding-top: 0;
      padding-left: 130px;
      background-position: 0 0;
    }
  }


  @media only screen and (min-width: $size-M2) {
    flex-flow: row wrap;

    li {
      width: 50%;
    }
  }


  @media only screen and (min-width: $size-L) {
    margin: 0 -40px;

    li {
      padding: 40px;
    }
  }


  @media only screen and (min-width: $size-XL3) {
    margin: 0 -80px;

    li {
      padding: 40px 80px;
    }
  }

}

.talent .benefits-list {

  li {

    .benefit-cost-saving {
      background-image: url("../Images/icon_variety.svg");
      background-size: 70px;
    }

    .individual-service {
      background-image: url("../Images/icon_free.svg");
      background-size: 70px;
    }

    .benefit-active-sourcing {
      background-image: url("../Images/icon_best_fit.svg");
      background-size: 100px;
    }

    .benefit-pre-qualified {
      background-image: url("../Images/icon_docs.svg");
    }

  }

}



.company .benefits-list {
  //margin: auto;
  //max-width: 1000px;

  li {

    .benefit-active-sourcing {
      background-image: url("../Images/icon_active_sourcing.svg");
      background-size: 80px;
    }

    .benefit-pre-qualified {
      background-image: url("../Images/icon_pre_selection.svg");
      background-size: 90px;
    }

    .benefit-cost-saving {
      background-image: url("../Images/icon_money_saving.svg");
      background-size: 84px;
    }

    .individual-service {
      background-image: url("../Images/icon_individual_service.svg");
      background-size: 84px;
    }
  }

}