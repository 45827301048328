//
// Price Compare Table CSS
//

.price-compare-table {
  .inner {
    max-width: 1100px;
  }

  .note {
    @include font-size(12);
  }
}

.price-compare-table-container {
  margin-bottom: 15px;

  .compare-col {
    background: #677f89;
    padding: 10px 40px;

    &:first-child {
      color: $white;
    }

    &:last-child {
      background: $green;
    }
  }

  ul {
    list-style: square;
    padding-left: 1.1em;

    li {
      padding-left: 10px;
      padding-top: 5px;
    }
  }

  @media only screen and (min-width: $size-M2) {
    display: flex;

    .compare-col {
      width: 50%;
    }
  }
}