//
// typo3 message CSS
//

.typo3-messages {
  @include ul-reset;
  margin-bottom: 30px;

  .alert {
    margin-top: 15px;
    padding: 15px 20px;
    color: $white;

    &:first-child {
      margin-top: 0;
    }
  }

  .alert-success {
    background: #77a248;
  }

  .alert-danger {
    background: #ca433b;
  }

  .alert-warning {
    background: #e9a33c;
  }

  .alert-info {
    background: #6babe0;
  }

}


// typo3 form errors

.form-errors {
  margin-bottom: 30px;
  padding-left: 34px;
  color: #ca433b;

  li {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}