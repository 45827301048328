//
// pricing CSS
//

.pricing {
  background: $grey-light;
  margin: 0 0 60px 0;
  padding: 80px 0;
  overflow-x: hidden;

  .inner {
  }

  .flex-row {
    display: flex;
    flex-flow: column;
    //overflow: hidden;
    align-items: center;
    margin: -40px;
  }

  h2 {
    text-align: center;
    margin: 0 0 60px 0;
  }

}

.price-box {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-flow: column;
  font-weight: 700;
  min-height: 105px;
  padding: 40px;

  &:first-child {
    margin-top: 0;
  }

  header {
    background: $green-light;
    color: $white;
    padding: 30px 40px;
    text-align: center;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;
      background: $grey-light;
      transform: rotate(45deg);
    }
  }

  s {
    opacity: .5;
  }

  &-title {
    @include font-size(24);
    text-transform: uppercase;
    font-weight: 600;
  }

  &-price {
    @include font-size(18);
    padding: 30px 40px;
    text-align: center;
    background: #f9f9f9;
    min-height: 105px;
    //min-height: 60px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    sup {
      @include font-size(10);
    }

    .price {
      font-weight: 700;
      padding: 2px 0;
      line-height: 1;
    }

    .price-comission {
      position: relative;
      margin-top: 0;
    }

    .small {
      font-weight: 300;
      @include font-size($font-size-XS);
    }
  }

  &-target {
    @include font-size(18);
    margin-top: 5px;
    font-weight: 500;
  }

  &-note {
    @include font-size(12);
    margin-top: 30px;
  }

  &-main {
    background: $white;
    padding: 20px 40px 40px 40px;
    display: flex;
    flex-flow: column;
    flex: 1;
  }

  ul {
    @include ul-reset;
    margin-bottom: 15px;

    li {
      padding: 12px 0;
      border-top: 1px dashed $grey-light2;
      font-weight: 400;

      sup {
        @include font-size(10);
      }

      &:first-child {
        border: none;
      }
    }
  }

  footer {
    text-align: center;
    margin-top: auto;
  }

  .more-info {
    display: block;
    color: $black;
    text-align: left;
    margin-bottom: 30px;
  }

  .btn-action {
    display: inline-block;
    background: $green-light;
    border-radius: 20px;
    padding: 12px 5px;
    color: $white;
    width: 100%;
    @include font-size(14);
    font-weight: 400;
    max-width: 240px;
  }

  &:nth-child(2) {
    header,
    .btn-action {
      background: $green;
    }
  }


  &:nth-child(3) {
    header,
    .btn-action {
      background: $green-advanced;
    }
  }

  /*
  &:nth-child(3) {
    header,
    .btn-action {
      background: $grey-pro;
    }
  }
  */

  &:nth-child(4) {
    header,
    .btn-action {
      background: $grey-enterprise;
    }
  }


  .promo-sticker {
    position: absolute;
    right: -25px;
    top: 100px;
    width: 100px;
    height: 100px;
    //background: red;
    background: url("../Images/icon_promotion.svg") no-repeat;
    background-size: 100% 100%;
    z-index: 6;
    transform: rotate(-14deg);
    display: flex;
    flex-flow: column;
    align-items: center;
    align-content: center;
    text-align: center;
    color: $white;
    font-weight: 600;
    padding-top: 10px;
    display: none; // temp hide promo

    .percent {
      @include font-size(28);
      line-height: 1.1;
    }
  }

}


@media only screen and (min-width: $size-L) {
  .pricing {

    .flex-row {
      flex-flow: row wrap;
      align-items: initial;
      margin: 0 -20px;
    }

    .price-box {
      width: calc(100% / 2);
      padding: 20px;
      margin: 0;
      max-width: none;
    }
  }
}


@media only screen and (min-width: $size-XL3) {

  .pricing {
    .price-box {
      width: calc(100% / 4);
    }
  }

}


@media only screen and (min-width: $size-XL) {
  .price-box {
    &-title {
      @include font-size(30);
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}


@media only screen and (min-width: $size-XL2) {

  .pricing {

    .flex-row {
      margin: 0 -40px;
    }
  }

  .price-box {
    padding: 0 40px;


    .promo-sticker {
      right: -10px;
    }
  }

}