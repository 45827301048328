//
// Main CSS
//

main {

  padding-top: 60px;
  padding-bottom: 100px;
  min-height: calc(100vh - 350px);

  > section {
    scroll-margin-top: 60px;
  }



  // temp

  .intro-text {
    max-width: 1200px;
    margin: 80px auto 60px auto;
    @include font-size(26);
    text-align: center;
  }


  .intro-text-two-cols {
    margin: 80px auto 60px auto;
    @include font-size(18);

    .text-two-cols {
      display: flex;

      p {
        width: 50%;
        line-height: 1.6;

        &:first-child {
          padding-right: 80px;
          border-right: 1px solid $grey-mid;
        }

        &:last-child {
          padding-left: 80px;
        }
      }
    }
  }



  @media only screen and (min-width: $size-XL1) {
    padding-top: 110px;
  }

}


.page-layout-2,
.page-layout-3 {
  main {
    padding-bottom: 0;
  }
}

.page-layout-3 {
  main {
    padding-top: 60px;
  }
}

.page-header.compact ~ main {

}