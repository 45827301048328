//
// Project Color Definitions
//


$grey-light: #ebeeef;
$grey-light2: #cccccc;
$grey-mid: #484848;
$grey-mid2: #555555;
$grey-mid3: #888888;
$grey-alt: #3d454a;

$grey-pro: #818e97;
$grey-enterprise: #677f89;

$grey-dark: #1a171b;

$green-light: #d2d981;
$green: #c2d12a;
$green-advanced: #7cb11c;

$white: #FFFFFF;
$black: #000000;