//
// Custom Mixins  @mixin
//

// font size in rem with px as base
@mixin font-size($sizeValue: 16) {
  font-size: $sizeValue / 16 + rem;
}

// Clearfix
@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

// Hide text
@mixin hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// UL Reset
@mixin ul-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Inline LIs
@mixin list-inline {
  padding-left: 0;
  list-style: none;
  > li {
    display: inline-block;
  }
}

// vertical align
@mixin vertical-align() {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: .1px;
  }
}

// center - vertical and horizontal
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Triangle => http://hackingui.com/front-end/10-best-scss-utilities/
@mixin triangle($direction, $size: 6px, $color: #222, $scale: 1) {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  @if ($direction == 'up'){
    border-bottom: $size solid $color;
    border-left: $scale * $size solid transparent;
    border-right: $scale * $size solid transparent;
  } @else if ($direction == 'down'){
    border-top: $size solid $color;
    border-left: $scale * $size solid transparent;
    border-right: $scale * $size solid transparent;
  } @else if ($direction == 'left'){
    border-top: $scale * $size solid transparent;
    border-bottom: $scale * $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == 'right'){
    border-top: $scale * $size solid transparent;
    border-bottom: $scale * $size solid transparent;
    border-left: $size solid $color;
  }
}

// visually hidden => https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visually-hidden {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  a:focus,
  input:focus,
  button:focus {
    position: static;
    width: auto;
    height:auto;
  }
}