//
// Helper CSS
//

.icon-missing {
  background: url("../Images/icon_cross.svg") no-repeat center right;
  background-size: 16px;
  margin-right: 5px;
}

.icon-check {
  background: url("../Images/icon_check.svg") no-repeat center right;
  background-size: 24px;
}