//
// Burger Icon CSS
//

.burger-icon {
  position: absolute;
  top: 9px;
  right: 35px;
  cursor: pointer;
  z-index: 99;
  background: none;
  border: none;
  padding: 10px 5px 20px 5px;
  //display: flex;

  &:focus-within {
    outline: 2px dashed $green;
  }

  span.icon {
    display: block;
    position: relative;
    height: 3px;
    background-color: $green;
    width: 36px;
    margin-top: 11px;
    transition-delay: 0.4s, 0.5s;

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $green;
      content: "";
      transition-duration: 0.3s, 0.3s;
      transition-delay: 0.3s, 0s;
    }

    &::before {
      top: -11px;
      transition-property: top, transform;
    }

    &::after {
      bottom: -11px;
      transition-property: bottom, transform;
    }
  }

  span.text {
    display: block;
    text-transform: uppercase;
    color: $white;
    @include font-size($font-size-XS);
    line-height: 1;
    padding: 16px 0 0 0;
    font-weight: 600;
    visibility: hidden;
  }

  @media only screen and (min-width: $size-XL1) {
    display: none;
  }

}

.nav-main.active {
  .burger-icon {
    span.icon {
      background: none !important;
      //transition-delay: 0.3s, 0.3s;
      transition-delay: 0ms;

      &::before {
        top: 0;
        transform: rotate(45deg);
        transition-delay: 0s, 0.3s;
      }
      &::after {
        bottom: 0;
        transform: rotate(-45deg);
        transition-delay: 0s, 0.3s;
      }
    }
  }
}