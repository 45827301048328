//
// price rate compare CSS
//

.price-rate-compare {
  margin: 120px auto 40px auto;
  max-width: 700px;
  scroll-margin-top: 120px;

  .price-rate-compare-inner {
    padding: 0 0 0 30px;
  }

  h2 {
    margin: 0 0 60px 0;
  }

  @media (min-width: $size-XS) {
    .price-rate-compare-inner {
      padding-left: 50px;
      padding-right: 0;
    }
  }

  @media (min-width: $size-S3) {
    .price-rate-compare-inner {
      padding-left: 80px;
      padding-right: 0;
    }
  }
}

.price-rate-compare-info-container {
  width: 100%;
  position: relative;
  padding-bottom: 74.5%;
  height: 0;
  overflow: hidden;
  //background: rgba(blue, .3);
}

.price-rate-compare-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../Images/price_rate_compare.svg") no-repeat;
  background-size: 100%;
  //min-height: 447px;
  display: flex;
  text-align: center;
  @include font-size(22);

  .price {
    @include font-size(40);
  }

  .col-left {
    width: 43%;
    color: $white;
    padding: 40px 10px;
  }

  .col-right {
    width: 43%;
    display: flex;
    flex-flow: column;

    .bottom {
      margin-top: auto;
      padding-bottom: 15px;
    }
  }

  .in-arrow {
    height: 77%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    justify-items: center;
    align-items: center;

    .price {
      color: $green;
      margin: 5px 0;
    }
  }


  @media (max-width: $size-S21) {

    @include font-size(18);

    .price {
      @include font-size(32);
    }

    .col-right {
      .bottom {
        padding-bottom: 5px;
      }
    }

  }


  @media (max-width: $size-S1) {

    @include font-size(12);

    .price {
      @include font-size(24);
    }

    .col-right {
      .bottom {
        padding-bottom: 2px;
      }
    }

  }
}