//
// Social Media CSS
//


.social-media {

  display: flex;
  justify-items: center;
  justify-content: center;
  padding-top: 60px;
  margin-top: auto;

  //display: none;

  ul {
    @include ul-reset;
    overflow: hidden;

    li {
      float: left;
      margin-left: 15px;

      a {
        display: block;
        overflow: hidden;
      }
    }
  }

  a {
    text-indent: -99999px;
    width: 35px;
    height: 35px;
  }

  .xing {
    background: url('../Images/icon_xing_white.svg') no-repeat;
  }
  .linkedin {
    background: url('../Images/icon_linked_white.svg') no-repeat;
  }
  .twitter {
    background: url('../Images/icon_twitter_white.svg') no-repeat;
  }


  @media only screen and (min-width: $size-L3) {
    justify-items: right;
    justify-content: end;
    padding-top: 0;
  }

}