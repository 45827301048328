//
// CE Hero Image CSS
//

.ce-hero_image {
  margin-bottom: 60px;

  @media only screen and (min-width: $size-L3) {
    margin-bottom: 120px;
  }

  img {
    max-width: none;
    width: 100%;
  }
}