//
// Call To Action CSS
//

.call-to-action {

  padding: 60px 40px;
  text-align: center;

  h3 {
    @include font-size($font-size-XL);
    text-transform: none;
    margin-bottom: 5px;
  }

  p {
    @include font-size($font-size-L);
  }

  a {
    display: inline-block;
    @include font-size($font-size-L);
    text-transform: uppercase;
    color: $grey-dark;
    border: 2px solid $green;
    padding: 6px 20px;

    &:hover {
      color: $white;
      background: $green;
    }

    &.btn-inverse {
      background: $white;
      color: $green;

      &:hover {
        background: transparent;
        color: $white;
      }
    }
  }

  /*
  @media screen and (max-width: $size-L) {
    height: auto;
    padding-top: 5px;
    padding-bottom: 30px;
  }


  @media screen and (max-width: $size-S4) {
    .btn-group {
      a {
        margin-bottom: 15px;
      }
    }
  }
  */

}